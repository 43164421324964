import {connect} from '../../runtime-context/context'
import {DetailsPageAppProps} from '../../app/interfaces'
import {isNewMobileCheckoutEnabled} from '../../../../../commons/selectors/experiments'
import {PriceItem as Presentation} from './price-item'
import {PriceItemRuntimeProps, PriceItemOwnProps} from './interfaces'

const mapRuntime = ({state}: DetailsPageAppProps): PriceItemRuntimeProps => ({
  newMobileCheckout: isNewMobileCheckoutEnabled(state.experiments),
})

export * from './interfaces'

export const PriceItem = connect<PriceItemOwnProps, PriceItemRuntimeProps>(mapRuntime)(Presentation)
