import {getEventTitle, isTicketed, isWithoutRegistration} from '@wix/wix-events-commons-statics'
import {withTranslation} from '@wix/yoshi-flow-editor'
import {isAboutSectionVisible, isMapVisible, isSecondRegistrationButtonVisible} from '../../selectors/event'
import {isMembersVisibleWithGuests} from '../../selectors/members'
import {isMoreDatesButtonVisible, isMoreDatesLabelVisible} from '../../selectors/occurrences'
import {getPurchasablePlansWithValidityText, showPaidPlans} from '../../selectors/paid-plans'
import {getContentAlignment, getTexts, isScheduleVisible, isSocialShareVisible} from '../../selectors/settings'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/context'
import {hasScheduleItems} from '../../selectors/schedule'
import {showGroupActivity} from '../../selectors/groups'
import {EventDetails as Presentation} from './event-details'
import {EventDetailsOwnProps, EventDetailsRuntimeProps} from './interfaces'

const mapRuntimeToProps = (
  {state, actions: {handleRSVP, detailsPageLoaded}}: DetailsPageAppProps,
  {t}: EventDetailsOwnProps,
): EventDetailsRuntimeProps => {
  const event = state.event
  const componentSettings = state.component.settings
  const plans = getPurchasablePlansWithValidityText(state, t)
  const withoutRegistration = isWithoutRegistration(event)
  const paidPlansVisible = showPaidPlans(state) && Boolean(plans.length) && !withoutRegistration
  const groupVisible = showGroupActivity(state) && !withoutRegistration
  const membersVisible = isMembersVisibleWithGuests(state)
  const membersDividerVisible =
    membersVisible &&
    (isAboutSectionVisible(state) || isSecondRegistrationButtonVisible(state) || !paidPlansVisible || groupVisible)
  const groupsDividerVisible = groupVisible && !isAboutSectionVisible(state) && !membersDividerVisible
  const ticketedEvent = isTicketed(event)
  const scheduleVisible = isScheduleVisible(componentSettings) && hasScheduleItems(state)

  return {
    eventTitle: getEventTitle(event),
    texts: getTexts(componentSettings),
    contentAlignment: getContentAlignment(componentSettings),
    ticketedEvent,
    membersVisible,
    membersDividerVisible,
    mapVisible: isMapVisible(state),
    aboutSectionVisible: isAboutSectionVisible(state),
    showPaidPlans: paidPlansVisible,
    handleRSVP,
    detailsPageLoaded,
    hasTickets: ticketedEvent && Boolean(state.tickets.length),
    socialShareVisible: isSocialShareVisible(componentSettings),
    scheduleVisible,
    moreDatesVisible: isMoreDatesLabelVisible(state),
    moreDatesButtonVisible: isMoreDatesButtonVisible(state),
    groupVisible,
    groupsDividerVisible,
    withoutRegistration,
  }
}

export const EventDetails = withTranslation()(
  connect<EventDetailsOwnProps, EventDetailsRuntimeProps>(mapRuntimeToProps)(Presentation),
)
export * from './interfaces'
