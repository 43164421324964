export interface PriceItemOwnProps {
  price: string
  hook: string
  label: string
  size?: PriceItemSize
}

export interface PriceItemRuntimeProps {
  newMobileCheckout: boolean
}

export interface PriceItemProps extends PriceItemRuntimeProps, PriceItemOwnProps {}

export enum PriceItemSize {
  small,
  medium,
  big,
  extraBig,
}
